/* Resume.css */
.resume-page {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    color: #f0f0f0;
    background-color: #1a1a1a;
  }
  
  .resume-header {
    margin-bottom: 40px;
  }
  
  .resume-header h2 {
    font-size: 3rem;
    color: #f0f0f0;
    border-bottom: 2px solid #ff9800;
    display: inline-block;
    padding-bottom: 10px;
  }
  
  .experience-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
  
  .experience-item {
    background-color: #282c34;
    padding: 20px;
    border-radius: 10px;
    color: #ffffff;
  }
  
  .experience-item h3 {
    font-size: 2rem;
    color: #ff9800;
  }
  
  .experience-item h4 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .experience-item span {
    font-style: italic;
    color: #cccccc;
    margin-bottom: 10px;
    display: block;
  }
  
  .experience-item p {
    margin-bottom: 10px;
  }
  
  .experience-item ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .experience-item ul li {
    margin-bottom: 10px;
  }
  