.sobre-mim-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
    background-color: #282c34;
    border-radius: 10px;
  }
  
  h1, h2 {
    color: #61dafb;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }
  
  .sobre-mim-container h2 {
    margin-top: 20px;
    color: #ff9800;
  }
  
  .sobre-mim-container p, li {
    color: #e0e0e0;
  }
  