.contato-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1a1a1a;
  }
  
  .contato-form {
    background-color: #282c34;
    padding: 40px;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    color: white;
  }
  
  .contato-form h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #61dafb;
  }
  
  .contato-form p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .contato-form a {
    color: #61dafb;
    text-decoration: none;
  }
  
  .contato-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contato-form input, 
  .contato-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    color: black;
  }
  
  .contato-form button {
    background-color: #ff9800;
    color: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  
  .contato-form button:hover {
    background-color: #f57c00;
  }
  