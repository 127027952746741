/* Estilo Global */
body {
  margin: 0;
  padding: 0;
  background-color: #1a1a1a;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Evita rolagem horizontal */
}
@font-face {
  font-family: 'YesScript';
  src: url('../public/fontes/one-day.otf') format('truetype'); /* Ajuste o caminho para o local correto */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box;
}

.logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none; /* Remove o sublinhado */
  font-family: 'OneDay', sans-serif; /* Aplica a fonte One Day */
  transition: color 0.3s ease;
}
.logo:hover {
  color: #ff9800; /* Cor ao passar o mouse */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* Centraliza os itens no meio da página */
}
nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

button.get-in-touch {
  background-color: #e0e0e0;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none; /* Remove o sublinhado */
}

button.get-in-touch:hover {
  background-color: #cccccc; /* Muda a cor ao passar o mouse */
  color: black; /* Garante que o texto não mude de cor */
  text-decoration: none; /* Remove o sublinhado ao passar o mouse */
}

.main-content {
  padding: 20px;
  margin-top: 80px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr); /* 12 colunas */
  grid-gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Centraliza o grid */
}

.grid-item {
  background-color: #282c34;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.about-section {
  grid-column: span 6;
}

.resume-section,
.github-section {
  grid-column: span 3;
}
.resume-section {
  text-align: center;
}

.resume-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Suaviza a transição de cor */
  color: white; /* Cor original do ícone */
}

.resume-section:hover .resume-icon {
  color: orange; /* Cor laranja ao passar o mouse */
}
.sidequests-section {
  grid-column: span 3; 
  text-align: center;
}

.stuff-section {
  grid-column: span 12; /* Stuff I Like ocupa 9 colunas */
  margin-bottom: 20px; /* Espaçamento inferior */
}
.sidequests-icon {
  font-size: 3rem;
  color: white;
  transition: color 0.3s ease; /* Suaviza a transição de cor */
}
.sidequests-section:hover .sidequests-icon {
  color: #ff9800; /* Cor laranja ao passar o mouse */
}
.github-section {
  text-align: center;
}

.github-icon {
  font-size: 5rem;
  margin-bottom: 15px;
  transition: color 0.3s ease; /* Suaviza a transição da cor */
  color: white; /* Cor original */
}

.github-icon:hover {
  color: orange; /* Cor laranja ao passar o mouse */
}
.icons i {

  font-size: 2.5rem;
  margin: 0 10px;
  color: #FFF; /* Cor cinza inicial */
  transition: color 0.3s ease; /* Suaviza a transição de cor */
}

.icons i:hover {
  color: initial; /* Retorna à cor original do ícone ao passar o mouse */
}

footer {
  color: white;
  padding: 20px;
  text-align: center;
  margin-top: 40px;
}

.avatar {
  background-image: url('../src/components/arquivos/avatar.png');
  background-size: cover;
  background-position: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.scroll-to-about {
  margin-top: 20px; /* Adicione um espaçamento entre o texto e o ícone */
}

.scroll-to-about a {
  color: inherit; /* Mantém a cor atual do texto */
  text-decoration: none; /* Remove a sublinha do link */
}

.scroll-to-about a:hover {
  color: #007bff; /* Adicione uma cor de hover (opcional) */
}
